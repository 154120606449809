import React, {Fragment, lazy, useState} from 'react';
import {NavLink} from "react-router-dom";
import {Bars3Icon, ChevronDownIcon, EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/solid";
import {Dialog, Menu, Transition} from "@headlessui/react";

const HeaderSlideOver = lazy(() => import('./HeaderSlideOver'));

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function Header() {
    const [open, setOpen] = useState<boolean>(false);
    const currentURL = window.location.pathname;
    const logo = "https://assets.ozlaloc.fr/logo/logo_full_transparent.webp";
    const [show, setShow] = useState<boolean>(true);
    //Old ClassName
    return (
        <header className="bg-white rounded-b-[4rem] lg:rounded-b-[18rem]">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex flex-1">
                    <div className="hidden lg:block">
                        <a href={"tel:+33756963535"}
                           className={' flex gap-x-2 hover:text-ozlaloc-100 duration-500'}><PhoneIcon
                            className={'my-auto w-4 h-4'}/>07 56 96 35 35</a>
                        <a href={"mailto:contact@ozlaloc.com"}
                           className={' flex gap-x-2 hover:text-ozlaloc-100 duration-500'}><EnvelopeIcon
                            className={'my-auto w-4 h-4'}/>contact@ozlaloc.com</a>

                    </div>
                    <div className="flex lg:hidden">
                        <a aria-label={'Passer un appel à Ozlaloc'} href="tel:+33756963535"
                           className="text-black flex items-center gap-x-2 pl-[16px]">
                            <PhoneIcon className={'w-5 h-5'}/>
                        </a>
                    </div>
                </div>
                <NavLink to="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Ozlaloc</span>
                    <img className={'h-3/4 w-3/4 mx-auto'} title={'Ozlaloc Location louez du matériel'} src={logo}
                         alt={"Ozlaloc logo"}/>
                </NavLink>
                <div className="flex flex-1 justify-end">
                    <Menu as="div" className="hidden lg:relative lg:inline-block lg:text-left">
                        <div>
                            <Menu.Button
                                className="mx-4 inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                                Nos agences

                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>

                                        <a
                                            href="https://ozlaloc.fr/magasins/Nouvoitou"
                                            className={classNames(
                                                'text-gray-700 ',
                                                'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 mx-2 rounded-lg duration-100 my-1'
                                            )}
                                        >
                                            Nouvoitou
                                        </a>

                                    </Menu.Item>
                                    <Menu.Item>

                                        <a
                                            href="https://ozlaloc.fr/magasins/Liffre/"
                                            className={classNames(
                                                'text-gray-700 ',
                                                'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 mx-2 rounded-lg duration-100 my-1'
                                            )}
                                        >
                                            Liffré
                                        </a>

                                    </Menu.Item>


                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={setOpen}>
                            <div className="fixed inset-0"/>

                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div
                                        className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                                <HeaderSlideOver setOpen={setOpen}/>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                    <div
                        className="mx-4 inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                        <Bars3Icon className={'w-5 h-5'} onClick={() => setOpen(!open)}/>
                    </div>


                </div>
            </nav>
            {show && (
            <div
                className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-50">
                <div
                    className="pointer-events-auto flex items-center justify-between gap-x-6 hidden bg-yellow-100 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
                    <p className="text-sm leading-6 text-white">
                        <a href="https://ozlaloc.fr/magasins/Nouvoitou" className={"text-yellow-700 hidden"}>
                            <strong className="font-semibold text-yellow-800">Fermeture exceptionnelle</strong>
                            <svg viewBox="0 0 2 2" className="mx-2 hidden inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                                <circle cx="1" cy="1" r="1"/>
                            </svg>
                            Nos agences seront fermées du 28 décembre au 2 janvier inclus. &nbsp;<span
                            aria-hidden="true">&rarr;</span>
                        </a>
                    </p>
                    <button type="button" className="-m-1.5 flex-none p-1.5 " onClick={() => setShow(!show)}>
                        <span className="sr-only">Dismiss</span>
                        <svg className="h-5 w-5 text-yellow-700" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
                        </svg>
                    </button>
                </div>
            </div>
            )}
        </header>
    );
}

export default Header;
